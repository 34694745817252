import { useToast } from "vue-toastification";
const toast = useToast();
import { RequestGateway } from "@/store/RequestGateway";
import StorageUtil from "@/utils/StorageUtil";

export default {
    state: {
        usersList: [],
        userTotalRow: 0,
    },
    getters: {
        getUsersList: (state) => state.usersList,
        getUsers: (state) => state.users,
        getUserTotalRow: (state) => state.userTotalRow,
    },
    mutations: {
        GET_USERS_LIST(state, data) {
            state.usersList = data.data;
            state.userTotalRow = data.totalCount;
        },
        GET_USERS(state, data) {
            state.users = data;
        },
    },
    actions: {
        async fetchUsersList(
            { commit },
            { page = 1, perPage = 10, search = "", showLoading = false } = {}
        ) {
            if (showLoading) {
                commit("CHANGE_LOADING_STATUS", true);
            }
        
            const feature = "inhouse";
            const url = `/organizations/${StorageUtil.getSessionData(
                "currentOrganization"
            )}/users?page=${page}&perPage=${perPage}&search=${encodeURIComponent(search)}`;
            const options = {
                headers: {
                    Authorization: `Bearer ${StorageUtil.getSessionData("accessToken")}`,
                },
            };
        
            try {
                const response = await RequestGateway.get(feature, url, options);
                const users = response?.data?.data || [];
                const totalCount = response?.data?.totalCount || 0;
        
                commit("GET_USERS_LIST", { data: users, totalCount });
                return response.data;
            } catch (error) {
                toast.error("Error fetching users", { timeout: 2000 });
                throw error; // Ensure the component handles this in `.catch`
            } finally {
                if (showLoading) {
                    commit("CHANGE_LOADING_STATUS", false);
                }
            }
        },
        async fetchUser({ commit }, userId) {
            const feature = "inhouse";
            const url = `/organizations/${StorageUtil.getSessionData(
                "currentOrganization"
            )}/users/${userId}`;
            const options = {
                headers: {
                    Authorization: `Bearer ${StorageUtil.getSessionData(
                        "accessToken"
                    )}`,
                },
            };

            try {
                const response = await RequestGateway.get(
                    feature,
                    url,
                    options
                );
                return response.data;
            } catch (error) {
                toast.error(error.message, {
                    timeout: 2000,
                });
                return error;
            }
        },
        async createUser({ commit }, payload) {
            const feature = "inhouse";
            const url = `/organizations/${StorageUtil.getSessionData(
                "currentOrganization"
            )}/users`;
            const options = {
                headers: {
                    Authorization: `Bearer ${StorageUtil.getSessionData(
                        "accessToken"
                    )}`,
                },
                data: payload,
            };

            try {
                const response = await RequestGateway.post(
                    feature,
                    url,
                    payload,
                    options
                );
                return response;
            } catch (error) {
                return error;
            }
        },
        async updateUser({ commit }, { payload, userId }) {
            const feature = "inhouse";
            const url = `/organizations/${StorageUtil.getSessionData(
                "currentOrganization"
            )}/users/${userId}`;
            const options = {
                headers: {
                    Authorization: `Bearer ${StorageUtil.getSessionData(
                        "accessToken"
                    )}`,
                },
                data: payload,
            };

            try {
                const response = await RequestGateway.put(
                    feature,
                    url,
                    payload,
                    options
                );
                return response;
            } catch (error) {
                toast.error(error.message, {
                    timeout: 2000,
                });
                return error;
            }
        },
        async deleteUser({ commit }, userId) {
            const feature = "inhouse";
            const url = `/organizations/${StorageUtil.getSessionData(
                "currentOrganization"
            )}/users/${userId}`;
            const options = {
                headers: {
                    Authorization: `Bearer ${StorageUtil.getSessionData(
                        "accessToken"
                    )}`,
                },
            };

            try {
                const response = await RequestGateway.delete(
                    feature,
                    url,
                    options
                );
                return response;
            } catch (error) {
                return error;
            }
        },
    },
};
