import { useToast } from "vue-toastification";
import { RequestGateway } from "@/store/RequestGateway";
import StorageUtil from "@/utils/StorageUtil";

const toast = useToast();
export default {
    state: {
        isActiveSubscription: false,
        billingInformation: null,
        invoiceDetails: null,
        paymentMethodHistory: null,
        activeSubscriptionDetails: null,
        activePlanDetails: null,
        planDetails: null,
        remainingDurationDetails: null,
        remainingStorageDetails: null,
    },
    getters: {
        getIsActiveSubscription(state) {
            return state.isActiveSubscription;
        },
        getBillingInformation(state) {
            return state.billingInformation;
        },
        getInvoiceDetails(state) {
            return state.invoiceDetails;
        },
        getPaymentMethodHistory(state) {
            return state.paymentMethodHistory;
        },
        getActiveSubscriptionDetails(state) {
            return state.activeSubscriptionDetails;
        },
        getActivePlanDetails(state) {
            return state.activePlanDetails;
        },
        getPlanDetails(state) {
            return state.planDetails;
        },
        getRemainingDurationDetails(state) {
            return state.remainingDurationDetails;
        },
        getRemainingStorageDetails(state) {
            return state.remainingStorageDetails;
        },
    },
    mutations: {
        SET_ACTIVE_SUBSCRIPTION(state, isActive) {
            state.isActiveSubscription = isActive;
        },
        SET_BILLING_INFORMATION(state, billingInfo) {
            state.billingInformation = billingInfo;
        },
        SET_INVOICE_DETAILS(state, invoiceDetails) {
            state.invoiceDetails = invoiceDetails;
        },
        SET_PAYMENT_METHOD_HISTORY(state, paymentMethodHistory) {
            state.paymentMethodHistory = paymentMethodHistory;
        },
        SET_ACTIVE_SUBSCRIPTION_DETAILS(state, activeSubscriptionDetails) {
            state.activeSubscriptionDetails = activeSubscriptionDetails;
        },
        SET_ACTIVE_PLAN_DETAILS(state, activePlanDetails) {
            state.activePlanDetails = activePlanDetails;
        },
        SET_PLAN_DETAILS(state, planDetails) {
            state.planDetails = planDetails;
        },
        SET_REMAINING_DURATION(state, remainingDuration) {
            state.remainingDurationDetails = remainingDuration;
        },
        SET_REMAINING_STORAGE(state, remainingStorage) {
            state.remainingStorageDetails = remainingStorage;
        },
    },
    actions: {
        async fetchIsActiveSubscription({ commit }) {
            const feature = "subscription";
            const url = `/api/v1/organizations/${StorageUtil.getSessionData(
                "currentOrganization"
            )}/subscriptions/is-active`;
            const options = {
                headers: {
                    Authorization: `Bearer ${StorageUtil.getSessionData(
                        "accessToken"
                    )}`,
                },
            };

            try {
                const response = await RequestGateway.get(
                    feature,
                    url,
                    options
                );
                commit("SET_ACTIVE_SUBSCRIPTION", response.data);
                return response.data;
            } catch (error) {
                toast.error(error.message, { timeout: 2000 });
                return error;
            } finally {
                commit("CHANGE_LOADING_STATUS", false);
            }
        },
        async fetchBillingInformation({ commit }) {
            const feature = "subscription";
            const url = `/api/v1/organizations/${StorageUtil.getSessionData(
                "currentOrganization"
            )}/billing/info`;
            const options = {
                headers: {
                    Authorization: `Bearer ${StorageUtil.getSessionData(
                        "accessToken"
                    )}`,
                },
            };

            try {
                const response = await RequestGateway.get(
                    feature,
                    url,
                    options
                );

                commit("SET_BILLING_INFORMATION", response.data);
                return response.data;
            } catch (error) {
                toast.error(error.message, { timeout: 2000 });
                return error;
            } finally {
                commit("CHANGE_LOADING_STATUS", false);
            }
        },
        async fetchInvoiceDetails({ commit }) {
            const feature = "subscription";
            const url = `/api/v1/organizations/${StorageUtil.getSessionData(
                "currentOrganization"
            )}/subscriptions/active/invoices`;
            const options = {
                headers: {
                    Authorization: `Bearer ${StorageUtil.getSessionData(
                        "accessToken"
                    )}`,
                },
            };

            try {
                const response = await RequestGateway.get(
                    feature,
                    url,
                    options
                );
                commit(
                    "SET_INVOICE_DETAILS",
                    response.data?.SubscriptionInvoice
                );
                return response.data;
            } catch (error) {
                toast.error(error.message, { timeout: 2000 });
                return error;
            } finally {
                commit("CHANGE_LOADING_STATUS", false);
            }
        },
        async fetchPaymentMethodHistory({ commit }) {
            const feature = "subscription";
            const url = `/api/v1/organizations/${StorageUtil.getSessionData(
                "currentOrganization"
            )}/billing/payment-methods`;
            const options = {
                headers: {
                    Authorization: `Bearer ${StorageUtil.getSessionData(
                        "accessToken"
                    )}`,
                },
            };

            try {
                const response = await RequestGateway.get(
                    feature,
                    url,
                    options
                );
                commit(
                    "SET_PAYMENT_METHOD_HISTORY",
                    response.data?.PaymentMethods
                );
                return response.data;
            } catch (error) {
                toast.error(error.message, { timeout: 2000 });
                return error;
            } finally {
                commit("CHANGE_LOADING_STATUS", false);
            }
        },
        async fetchSubscription({ commit }) {
            const feature = "subscription";
            const url = `/api/v1/organizations/${StorageUtil.getSessionData(
                "currentOrganization"
            )}/subscriptions`;
            const options = {
                headers: {
                    Authorization: `Bearer ${StorageUtil.getSessionData(
                        "accessToken"
                    )}`,
                },
            };

            try {
                const response = await RequestGateway.get(
                    feature,
                    url,
                    options
                );

                return response.data;
            } catch (error) {
                toast.error(error.message, { timeout: 2000 });
                return error;
            } finally {
                commit("CHANGE_LOADING_STATUS", false);
            }
        },
        async fetchActiveSubscriptionDeatils({ commit }) {
            const feature = "subscription";
            const url = `/api/v1/organizations/${StorageUtil.getSessionData(
                "currentOrganization"
            )}/subscriptions/active`;
            const options = {
                headers: {
                    Authorization: `Bearer ${StorageUtil.getSessionData(
                        "accessToken"
                    )}`,
                },
            };

            try {
                const response = await RequestGateway.get(
                    feature,
                    url,
                    options
                );
                commit("SET_ACTIVE_SUBSCRIPTION_DETAILS", response.data);
                return response.data;
            } catch (error) {
                toast.error(error.message, { timeout: 2000 });
                return error;
            } finally {
                commit("CHANGE_LOADING_STATUS", false);
            }
        },
        async fetchActivePlanDeatils({ commit }, planId) {
            const feature = "subscription";
            const url = `/api/v1/plans/${planId}`;
            const options = {
                headers: {
                    Authorization: `Bearer ${StorageUtil.getSessionData(
                        "accessToken"
                    )}`,
                },
            };

            try {
                const response = await RequestGateway.get(
                    feature,
                    url,
                    options
                );
                commit("SET_ACTIVE_PLAN_DETAILS", response.data);
                return response.data;
            } catch (error) {
                toast.error(error.message, { timeout: 2000 });
                return error;
            } finally {
                commit("CHANGE_LOADING_STATUS", false);
            }
        },
        async cancelSubcription({ commit }, payload) {
            const feature = "subscription";
            const url = `/api/v1/organizations/${StorageUtil.getSessionData(
                "currentOrganization"
            )}/subscriptions/cancel`;
            const options = {
                headers: {
                    Authorization: `Bearer ${StorageUtil.getSessionData(
                        "accessToken"
                    )}`,
                },
            };

            try {
                const response = await RequestGateway.post(
                    feature,
                    url,
                    payload,
                    options
                );
                return response;
            } catch (error) {
                // console.log("error", error);
                toast.error(error.message, { timeout: 2000 });
                return error;
            } finally {
                commit("CHANGE_LOADING_STATUS", false);
            }
        },
        async fetchPlans({ commit }) {
            const feature = "subscription";
            const url = `/api/v1/plans`;
            const options = {
                headers: {
                    Authorization: `Bearer ${StorageUtil.getSessionData(
                        "accessToken"
                    )}`,
                },
            };

            try {
                const response = await RequestGateway.get(
                    feature,
                    url,
                    options
                );

                commit("SET_PLAN_DETAILS", response.data?.SubscriptionPlans);
                return response.data;
            } catch (error) {
                toast.error(error.message, { timeout: 2000 });
                return error;
            } finally {
                commit("CHANGE_LOADING_STATUS", false);
            }
        },
        async fetchRemainingDuration({ commit }) {
            const feature = "subscription";
            const url = `/api/v1/organizations/${StorageUtil.getSessionData(
                "currentOrganization"
            )}/subscriptions/active/remaining-duration`;
            const options = {
                headers: {
                    Authorization: `Bearer ${StorageUtil.getSessionData(
                        "accessToken"
                    )}`,
                },
            };

            try {
                const response = await RequestGateway.get(
                    feature,
                    url,
                    options
                );
                commit("SET_REMAINING_DURATION", response.data);
                return response.data;
            } catch (error) {
                toast.error(error.message, { timeout: 2000 });
                return error;
            } finally {
                commit("CHANGE_LOADING_STATUS", false);
            }
        },
        async fetchRemainingStorage({ commit }) {
            const feature = "subscription";
            const url = `/api/v1/organizations/${StorageUtil.getSessionData(
                "currentOrganization"
            )}/subscriptions/active/remaining-storage`;
            const options = {
                headers: {
                    Authorization: `Bearer ${StorageUtil.getSessionData(
                        "accessToken"
                    )}`,
                },
            };

            try {
                const response = await RequestGateway.get(
                    feature,
                    url,
                    options
                );
                commit("SET_REMAINING_STORAGE", response.data);
                return response.data;
            } catch (error) {
                toast.error(error.message, { timeout: 2000 });
                return error;
            } finally {
                commit("CHANGE_LOADING_STATUS", false);
            }
        },
        async upgradeSubcription({ commit }, payload) {
            const feature = "subscription";
            const url = `/api/v1/organizations/${StorageUtil.getSessionData(
                "currentOrganization"
            )}/subscriptions/upgrade`;
            const options = {
                headers: {
                    Authorization: `Bearer ${StorageUtil.getSessionData(
                        "accessToken"
                    )}`,
                },
            };

            try {
                const response = await RequestGateway.post(
                    feature,
                    url,
                    payload,
                    options
                );
                return response;
            } catch (error) {
                // console.log("error", error);
                toast.error(error.message, { timeout: 2000 });
                return error;
            } finally {
                commit("CHANGE_LOADING_STATUS", false);
            }
        },
    },
};
