import { useToast } from "vue-toastification";
const toast = useToast();
import { RequestGateway } from "@/store/RequestGateway";
import StorageUtil from "@/utils/StorageUtil";

export default {
    state: {
        isSessionCreateFail: false,
        currentSessionId: null,
        sessionList: [],
        sessionListTotalRow: 0,
        videoListBySessionId: [],
        videoListTotalRow: 0,
    },
    getters: {
        getIsSessionCreateFail: (state) => state.isSessionCreateFail,
        getCurrentSessionId: (state) => state.currentSessionId,
        sessionList: (state) => state.sessionList,
        getSessionListTotalRow: (state) => state.sessionListTotalRow,
        getVideoBySessionId: (state) => state.videoListBySessionId,
        getVideoListTotalRow: (state) => state.videoListTotalRow,
    },
    mutations: {
        IS_SESSION_CREATE_FAIL(state, val) {
            state.isSessionCreateFail = val;
        },
        UPDATE_CURRENT_SESSION_ID(state, id) {
            state.currentSessionId = id;
        },
        sessionList(state, data) {
            console.log("sateto session list", data);
            state.sessionList = data.sessionData;
            state.sessionListTotalRow = data.totalRow;
        },
        SET_VIDEO_BY_SESSION(state, data) {
            state.videoListBySessionId = data.reportData;
            state.videoListTotalRow = data.totalRow;
        },
    },
    actions: {
        async getAnalysisReport({ commit }, reportId) {
            const feature = "media";
            const url = `/organizations/${StorageUtil.getSessionData(
                "currentOrganization"
            )}/sessions/${reportId}/sessionReport`;
            const options = {
                headers: {
                    Authorization: `Bearer ${StorageUtil.getSessionData(
                        "accessToken"
                    )}`,
                },
            };

            try {
                const response = await RequestGateway.get(
                    feature,
                    url,
                    options
                );
                return response.data;
            } catch (error) {
                toast.error(error.message, {
                    timeout: 2000,
                });
                return error;
            }
        },
        async getNewSessionList(
            { commit },
            { page = 1, perPage = 10, search = "", showLoading = false } = {}
        ) {
            if (showLoading) {
                commit("CHANGE_LOADING_STATUS", true);
            }

            const feature = "media";
            const url = `/organizations/${StorageUtil.getSessionData(
                "currentOrganization"
            )}/sessions?page=${page}&perPage=${perPage}&search=${encodeURIComponent(
                search
            )}`;
            const options = {
                headers: {
                    Authorization: `Bearer ${StorageUtil.getSessionData(
                        "accessToken"
                    )}`,
                },
            };
            try {
                const response = await RequestGateway.get(
                    feature,
                    url,
                    options
                );
                if (response.data.data && response.data.data.length) {
                    commit("sessionList", {
                        sessionData: response.data.data,
                        totalRow: response.data.totalCount,
                    });
                    return response.data;
                }
            } catch (error) {
                commit("CHANGE_LOADING_STATUS", false);
                toast.error(error.message, {
                    timeout: 2000,
                });
                return error;
            } finally {
                if (showLoading) {
                    commit("CHANGE_LOADING_STATUS", false);
                }
            }
        },
        async downloadVideoDetailsBySession({ commit }, data) {
            const feature = "media";
            const url = `/organizations/${StorageUtil.getSessionData(
                "currentOrganization"
            )}/sessions/${data.sessionId}/videos/${data.videoId}/download`;
            const options = {
                headers: {
                    Authorization: `Bearer ${StorageUtil.getSessionData(
                        "accessToken"
                    )}`,
                },
            };

            try {
                const response = await RequestGateway.get(
                    feature,
                    url,
                    options
                );
                return response.data;
            } catch (error) {
                toast.error(error.message, {
                    timeout: 2000,
                });
                return error;
            }
        },
        async videoDetailsBySessionOutput({ commit }, data) {
            const feature = "media";
            const url = `/organizations/${StorageUtil.getSessionData(
                "currentOrganization"
            )}/sessions/${data.sessionId}/videos/${
                data.videoId
            }/outputs?perPage=200`;
            const options = {
                headers: {
                    Authorization: `Bearer ${StorageUtil.getSessionData(
                        "accessToken"
                    )}`,
                },
            };

            commit("CHANGE_LOADING_STATUS", true);
            try {
                const response = await RequestGateway.get(
                    feature,
                    url,
                    options
                );
                commit("CHANGE_LOADING_STATUS", false);
                return response.data;
            } catch (error) {
                commit("CHANGE_LOADING_STATUS", false);
                toast.error(error.message, {
                    timeout: 2000,
                });
                return error;
            }
        },
        async getVideoDetailsBySession({ commit },  { page = 1, perPage = 10, search = "", showLoading = false, Id } = {}) {
            const feature = "media";
            const url = `/organizations/${StorageUtil.getSessionData(
                "currentOrganization"
            )}/sessions/${Id}/videos?page=${page}&perPage=${perPage}&search=${encodeURIComponent(search)}`;
            const options = {
                headers: {
                    Authorization: `Bearer ${StorageUtil.getSessionData(
                        "accessToken"
                    )}`,
                },
            };

            try {
                const response = await RequestGateway.get(
                    feature,
                    url,
                    options
                );
               
                if (
                    response.data &&
                    response.data.data &&
                    response.data.data.length
                ) {
                        
                    commit("SET_VIDEO_BY_SESSION", {
                        reportData: response.data.data,
                        totalRow: response.data.totalCount,
                    });
                    return response.data;
                } else {
                    commit("SET_VIDEO_BY_SESSION", []);
                    return [];
                }
            } catch (error) {
                
                toast.error(error.message, {
                    timeout: 2000,
                });
                return error;
            }
        },
    },
};
