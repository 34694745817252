import { createStore } from "vuex";
import axios from "axios";
import news from "./app/news";
import users from "./app/users";
import roles from "./app/roles";
import report from "./app/report";
import analysis from "./app/analysis";
import dashboard from "./app/dashboard";
import subscription from "./app/subscription";
import StorageUtil from "@/utils/StorageUtil";
import { useToast } from "vue-toastification";
import router from "../router/index";
import { RequestGateway } from "@/store/RequestGateway";

const toast = useToast();
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    return Promise.reject(error);
  }
);

export default createStore({
  state: {
    sidebarCollasp: false,
    sidebarHidden: false,
    mobielSidebar: false,
    semidark: false,
    semiDarkTheme: "semi-light",
    isDark: true,
    skin: "default",
    theme: "dark",
    isOpenSettings: false,
    cWidth: "full",
    menuLayout: "vertical",
    navbarType: "sticky",
    footerType: "static",
    chartColors: {
      title: "red",
    },
    sessionStreamList: [],
    isTableLoading: false,
    streamLibraryList: [],
    scenarioObjectList: [],
    anomaliesList: [],
    mainClientList: [],
    selectedVideoIntSummary: null,
    deviceToken: "",
    role: StorageUtil.getLocalData("role") || "",
    firstName: StorageUtil.getLocalData("firstName") || "",
    userId: "",
  },
  getters: {
    theme: (state) => state.theme,
    skin: (state) => state.skin,
    isTableLoading: (state) => state.isTableLoading,
    getMainClientList: (state) => state.mainClientList,
    getSelectedVideoIntSummary: (state) => state.selectedVideoIntSummary,
    getDeviceToken: (state) => state.deviceToken,
    role: (state) => state.role,
    firstName: (state) => state.firstName,
    userId: (state) => state.userId,
  },
  mutations: {
    setSidebarCollasp(state) {
      state.sidebarCollasp = !state.sidebarCollasp;
    },
    SET_ROLES(state, role) {
      state.role = role;
      StorageUtil.setLocalData("role", role);
    },
    SET_USER_ID(state, userId) {
      state.userId = userId;
    },
    SET_FIRSTNAME(state, firstName) {
      state.firstName = firstName;
      StorageUtil.setLocalData("firstName", firstName);
    },
    toogleDark(state) {
      state.isDark = !state.isDark;
      state.theme = state.theme === "dark" ? "light" : "dark";
      document.body.classList.toggle(state.theme);
      localStorage.setItem("theme", state.theme);
    },
    toggleSettings(state) {
      state.isOpenSettings = !state.isOpenSettings;
    },
    toggleMsidebar(state) {
      state.mobielSidebar = !state.mobielSidebar;
    },
    toggleSemiDark(state) {
      state.semidark = !state.semidark;
      state.semiDarkTheme = state.semidark ? "semi-dark" : "semi-light";
      document.body.classList.toggle(state.semiDarkTheme);
      localStorage.setItem("semiDark", state.semidark);
    },
    CHANGE_LOADING_STATUS(state, value) {
      state.isTableLoading = value;
    },
    MAIN_CLIENT_LIST(state, data) {
      state.mainClientList = data;
    },
    SELETED_VIDEO_INT_SUMMARY(state, data) {
      state.selectedVideoIntSummary = data;
    },
    SET_DEVICE_TOKEN(state, val) {
      state.deviceToken = val;
    },
  },
  actions: {
    toogleDark({ commit }) {
      commit("toogleDark");
    },
    toggleSettings({ commit }) {
      commit("toggleSettings");
    },
    setSidebarCollasp({ commit }) {
      commit("setSidebarCollasp");
    },
    toggleMsidebar({ commit }) {
      commit("toggleMsidebar");
    },
    toggleSemiDark({ commit }) {
      commit("toggleSemiDark");
    },
    async setUserLogin({ commit }, data) {
      const feature = "inhouse";
      const url = `/login`;
      const options = {
        data,
      };

      try {
        const response = await RequestGateway.post(feature, url, data, options);
        return response.data;
      } catch (error) {
        return error;
      }
    },
    async sendSMS({ commit }, { message, number }) {
      try {
        const response = await axios.post(
          "https://fh6p6vs1vb.execute-api.ap-southeast-2.amazonaws.com/Prod/hello",
          { message, number },
          {
            headers: {
              "Content-Type": "application/json",
              "x-api-key": "canw5lh5vE9m51r6ddwOg3D3y3Hq599E6oh7pmMw",
            },
          }
        );
        console.log(response.data);
      } catch (error) {
        console.error(error);
        toast.error(error.message, {
          timeout: 2000,
        });
      }
    },
    async updatePassword({ commit }, payload) {
      const feature = "inhouse";
      const url = `/change-password`;
      const options = {
        headers: {
          Authorization: `Bearer ${StorageUtil.getSessionData("accessToken")}`,
        },
        data: payload,
      };

      try {
        const response = await RequestGateway.post(feature, url, payload, options);
        return response;
      } catch (error) {
        console.error(error);
        return error;
      }
    },
    async forgotPassword({ commit }, payload) {
      const feature = "inhouse";
      const url = `/forgot-password`;
      const options = {
        headers: {
          Authorization: `Bearer ${StorageUtil.getSessionData("accessToken")}`,
        },
        data: payload,
      };

      try {
        const response = await RequestGateway.post(feature, url, payload, options);
        return response;
      } catch (error) {
        console.error(error);
        return error;
      }
    },
  },
  modules: {
    news,
    users,
    roles,
    report,
    analysis,
    dashboard,
    subscription
  },
});
